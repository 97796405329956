@import url("//fonts.googleapis.com/css?family=Roboto:100,400,500,900");

html {
    background-color: rgb(240, 240, 240);
}

body,
html {
    padding: 0px;
    margin: 0px;
    border: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
}

p {
    margin: 0px;
}

button::-moz-focus-inner {
    border: 0;
}

img {
    max-width: 90%;
}

.row {
    max-width: 100% !important;
}

blockquote {
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
}

code {
    font-family: monospace;
    background-color: #eee;
    padding: 3px;
}

/* MUI underline effect */
.mui-underline {
    border: 0;
    -webkit-background-size: 0 2px, 100% 2px;
    background-size: 0 2px, 100% 2px;
    background-repeat: no-repeat;
    background-position: center bottom, center -webkit-calc(100%);
    background-position: center bottom, center calc(100%);
    background-color: rgba(0, 0, 0, 0);
    -webkit-transition: background 0s ease-out;
    -o-transition: background 0s ease-out;
    transition: background 0s ease-out;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    font-size: inherit;
    font-family: inherit;
    color: inherit;
}

.mui-underline:focus {
    background-repeat: no-repeat;
    background-position: center bottom, center -webkit-calc(100%);
    background-position: center bottom, center calc(100%);
    outline: 0;
    -webkit-background-size: 100% 2px, 100% 2px;
    background-size: 100% 2px, 100% 2px;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

/* Placeholders */
::-webkit-input-placeholder {
    color: inherit;
    font-size: inherit;
    opacity: 0.4;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: inherit;
    font-size: inherit;
    opacity: 0.4;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: inherit;
    font-size: inherit;
    opacity: 0.4;
}

:-ms-input-placeholder {
    color: inherit;
    font-size: inherit;
    opacity: 0.4;
}

div:empty:not(:focus):before {
    content: attr(placeholder);
    color: inherit;
    font-size: inherit;
    opacity: 0.4;
}

[contenteditable="true"]:empty:not(:focus):before {
    content: attr(placeholder);
    color: inherit;
    font-size: inherit;
    opacity: 0.4;
}

/* Messaging Animations */

.overlay-enter {
    opacity: 0.01;
    z-index: 100;
}

.overlay-enter.overlay-enter-active {
    transition: opacity 50ms ease-in-out;
    opacity: 1 !important;
}

.overlay-leave {
    opacity: 1;
    z-index: 100;
}

.overlay-leave.overlay-leave-active {
    opacity: 0.01;
    transition: opacity 100ms ease-in-out;
}

.dialog-appear {
    margin-top: -300px !important;
    position: relative !important;
}

.dialog-appear.dialog-appear-active {
    transition: all 100ms ease;
    margin-top: 0px !important;
}

.triage-enter {
    opacity: 0;
    margin-top: -500px !important;
    position: relative !important;
}

.triage-enter.triage-enter-active {
    opacity: 1;
    transition: all 300ms ease;
    margin-top: 0px !important;
}

.triage-appear {
    opacity: 0;
    margin-top: -500px !important;
    position: relative !important;
}

.triage-appear.triage-appear-active {
    opacity: 1;
    transition: all 300ms ease;
    margin-top: 0px !important;
}

.triage-leave {
    opacity: 1;
    margin-top: 0px !important;
    position: relative !important;
}

.triage-leave.triage-leave-active {
    opacity: 0;
    transition: all 300ms ease;
    margin-top: 500px !important;
}

.card-enter {
    opacity: 1;
    top: 100vh !important;
    height: calc(100vh - 66px);
    position: fixed !important;
    left: 0px;
    width: 50% !important;
    text-align: center;
    z-index: 2;
}

.card-enter.card-enter-active {
    opacity: 1;
    transition: top 300ms ease;
    top: 66px !important;
}

.card-appear {
    opacity: 1;
    top: 100vh !important;
    height: calc(100vh - 66px);
    position: fixed !important;
    left: 0px;
    width: 50% !important;
    text-align: center;
    z-index: 2;
}

.card-appear.card-appear-active {
    opacity: 1;
    transition: top 300ms ease;
    top: 66px !important;
}

.card-leave {
    opacity: 1;
    top: 66px !important;
    height: calc(100vh - 66px);
    transition: top 300ms ease;
    position: fixed !important;
    left: 0px !important;
    width: 50% !important;
    text-align: center;
}

.card-leave.card-leave-active {
    opacity: 1;
    top: calc(-100vh + 140px) !important;
}

.reverse-enter {
    opacity: 1;
    top: calc(-100vh + 140px) !important;
    height: calc(100vh - 66px);
    position: fixed !important;
    left: 0px;
    width: 50% !important;
    text-align: center;
    z-index: 2;
}

.reverse-enter.reverse-enter-active {
    opacity: 1;
    transition: top 300ms ease;
    top: 66px !important;
}

.reverse-appear {
    opacity: 1;
    top: calc(-100vh + 140px) !important;
    height: calc(100vh - 66px);
    position: fixed !important;
    left: 0px;
    width: 50% !important;
    text-align: center;
}

.reverse-appear.reverse-appear-active {
    opacity: 1;
    transition: top 300ms ease;
    top: 66px !important;
}

.reverse-leave {
    opacity: 1;
    top: 66px !important;
    height: calc(100vh - 66px);
    transition: top 300ms ease;
    position: fixed !important;
    left: 0px !important;
    width: 50% !important;
    text-align: center;
}

.reverse-leave.reverse-leave-active {
    opacity: 1;
    top: calc(100vh) !important;
}

.alerts-enter {
    bottom: -50px !important;
    z-index: 100;
    position: fixed;
    left: 0px;
}

.alerts-enter.alerts-enter-active {
    transition: bottom 200ms ease-in-out;
    bottom: 0px !important;
}

.alerts-leave {
    bottom: 0px !important;
    z-index: 100;
}

.alerts-leave.alerts-leave-active {
    bottom: -50px !important;
    transition: bottom 100ms ease-in-out;
}

.message-enter {
    right: -400px !important;
    z-index: 100;
    position: fixed !important;
}

.message-enter.message-enter-active {
    transition: all 0.5s ease-in-out !important;
    right: 10px !important;
}

.message-appear {
    right: -400px !important;
    z-index: 100;
    position: fixed !important;
}

.message-appear.message-appear-active {
    transition: all 0.5s ease-in-out !important;
    right: 10px !important;
}

.message-leave {
    z-index: 100;
    position: fixed !important;
    transition: all 0.3s ease !important;
    margin-top: 0px;
}

.message-leave.message-leave-active {
    margin-top: -400px !important;
}

.prediction-context:after {
    content: attr(data-prediction);
    color: gray;
}

span:after {
    content: attr(data-prediction);
    color: gray;
}

p:after {
    content: attr(data-prediction);
    color: gray;
}

div:after {
    content: attr(data-prediction);
    color: gray;
}

/* Loader */
.loader-1,
.loader-1:before,
.loader-1:after {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 20px solid transparent;
}

.loader-1:before {
    border-top-color: var(--inner-color);
    animation: spin 1s infinite;
    opacity: 1 !important;
}

.loader-1:after {
    animation: spin 1s infinite alternate;
    border-bottom-color: #ccc;
}

@-moz-keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@-o-keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.indeterminate {
    position: relative;
    width: 100%;
    height: 4px;
}

.indeterminate:before {
    content: "";
    position: absolute;
    height: 4px;
    background-color: var(--inner-color);
    animation: indeterminate_first 1.5s infinite ease-out;
    opacity: 1 !important;
}

.indeterminate:after {
    content: "";
    position: absolute;
    height: 4px;
    background-color: var(--inner-color2);
    animation: indeterminate_second 1.5s infinite ease-in;
    opacity: 1 !important;
}

@keyframes indeterminate_first {
    0% {
        left: -100%;
        width: 100%;
    }
    100% {
        left: 100%;
        width: 10%;
    }
}

@keyframes indeterminate_second {
    0% {
        left: -150%;
        width: 100%;
    }
    100% {
        left: 100%;
        width: 10%;
    }
}
